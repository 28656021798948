import { Link } from "react-router-dom";
import { HomeIcon, HomeIconHover, MultiIcon, MultiHoverIcon, SingleIcon, SingleHoverIcon, ActionIcon, ActionHoverIcon, ShooterIcon, ShooterHoverIcon, AdventureIcon, AdventureHoverIcon, RoleplayIcon, RoleplayHoverIcon, SimulatorIcon, SimulatorHoverIcon, StrategyIcon, StrategyHoverIcon, ArcadeIcon, ArcadeHoverIcon, SportIcon, SportHoverIcon, HorrorIcon, HorrorhoverIcon, OpenIcon, OpenHoverIcon, NarrativeIcon, NarrativeHoverIcon } from "../../images/category";
import {useTranslation} from "react-i18next";

const CategoryItem = (props) => {
    const { name, icon, link, key, isMouseDown } = props;
    const { t } = useTranslation();

    const categoriesIcons = [
        { normal: HomeIcon, hover: HomeIconHover },
        { normal: MultiIcon, hover: MultiHoverIcon },
        { normal: SingleIcon, hover: SingleHoverIcon },
        { normal: ActionIcon, hover: ActionHoverIcon },
        { normal: ShooterIcon, hover: ShooterHoverIcon },
        { normal: AdventureIcon, hover: AdventureHoverIcon },
        { normal: RoleplayIcon, hover: RoleplayHoverIcon },
        { normal: HomeIcon, hover: HomeIconHover },
        { normal: SimulatorIcon, hover: SimulatorHoverIcon },
        { normal: StrategyIcon, hover: StrategyHoverIcon },
        { normal: ArcadeIcon, hover: ArcadeHoverIcon },
        { normal: SportIcon, hover: SportHoverIcon },
        { normal: HorrorIcon, hover: HorrorhoverIcon },
        { normal: OpenIcon, hover: OpenHoverIcon },
        { normal: NarrativeIcon, hover: NarrativeHoverIcon },
    ];

    const handleLink = (e) => {
        if (isMouseDown) {
            e.preventDefault();
        }
    }

    return (
        <Link onClick={handleLink} key={key} to={link} className="categories__item" draggable="false" >
            <div className="categories__content">
                <div className="categories__icon">
                    <img className="categories__icon-normal" src={categoriesIcons[icon].normal}
                        alt="action category" draggable="false" />
                    <img className="categories__icon-hover" src={categoriesIcons[icon].hover}
                        alt="action category" draggable="false" />
                </div>
                <div className="categories__text">{t(name)}</div>
            </div>
        </Link>
    );
}

export default CategoryItem;