import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMainService from "../../services/MainService";
import { Google, Telegram } from "../../images/socials/negative";
import Modal from "../modal/Modal";
import Select from "../select/Select";
import { AuthContext } from "../../providers/AuthProvider";
import { useInput } from "../../hooks/input/input.hook";
import { DateTime } from "luxon";
import { setLocalStorageWithExpiry } from "../../services/setLocalStorageWithExpiry";
import { useTranslation } from "react-i18next";

const RegisterModal = ({ open, setOpen, setModal }) => {
    const { t } = useTranslation();
    const [username, usernameInput, isUsername, setIsUsername] =
        useInput({ type: 'text', theme: t("enter-login"), extraClass: 'line' });
    const [password, passwordInput, isPassword, setIsPassword] =
        useInput({ type: 'password', theme: 'Пароль', extraClass: 'line', error: t("password-invalid") });
    const [email, emailInput, isEmail, setIsEmail] =
        useInput({ type: 'text', theme: 'E-mail', extraClass: 'line', error: t("email-invalid") });
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const {getAllCountries, registerUser, clearError, error, getCurrentUser} = useMainService();

    const auth = () => {
        let valid = true;

        if (username === '') {
            setIsUsername(false);
            valid = false;
        } else {
            setIsUsername(true);
        }

        const checkPassword = (pass) => {
            const regEx = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z\d]).{6,}$/;
            return regEx.test(pass) && pass.length >= 6;
        }
        if (!checkPassword(password)) {
            setIsPassword(false);
            valid = false;
        } else {
            setIsPassword(true);
        }

        const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegExp.test(email)) {
            setIsEmail(false);
            valid = false;
        } else {
            setIsEmail(true);
        }

        if (valid && country !== -1) {
            registerUser(username, email, password, country)
                .then(data => {
                    getCurrentUser(username, password)
                        .then(data => {
                            if (data) {
                                const expiryDate = DateTime.fromISO(data.expired, { zone: 'utc' }).setZone('local');
                                setLocalStorageWithExpiry('token', data.token, expiryDate.toISO());
                                authContext.setIsAuth(true);
                            }
                        })
                        .catch(log => console.log(log));
                });
            setOpen(false);
            clearError();

        }
    }

    useEffect(() => {
        getAllCountries()

            .then(data => setCountries(data.map((item) => ({ text: item.name, value: item.id }))));
    }, [getAllCountries]);

    return (
        <>
            <Modal open={open} onClose={() => { setOpen(false); setModal(); }}>
                <div className="modal__title">{t("registration-header")}</div>
                <div className="box">
                    <div className="modal__icon small">
                        <img src={Google} alt="Google"/>
                    </div>
                    <div className="modal__icon small">
                        <img src={Telegram} alt="Telegram" />
                    </div>
                </div>

                {usernameInput}
                {emailInput}
                {passwordInput}

                <Select text={t("country")} items={countries} setAnswer={setCountry} />
                <div onClick={auth} onKeyDown={(key) => {
                    if (key.key === "Enter") auth();
                }} className={"btn btn-light line"}>
                    <p>{t("button-create-account")}</p>
                </div>
                <div className="modal__link" onClick={() => { setModal() }}>{t("have-account")}</div>
            </Modal>
        </>
    );
}

export default RegisterModal;
