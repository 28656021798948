import List from "../list/List";
import NavbarItem from "../navbarItem/NavbarItem";
import { useEffect, useState } from "react";
import useMainService from "../../services/MainService";
import { useLocation } from "react-router-dom";
import { HomeIcon, HomeGreen, MoneyIcon, MoneyGreen, CheckIcon, CheckGreen, ArcadeIcon, ArcadeGreen } from '../../images/category/navbar';
import { useTranslation } from "react-i18next";

const CategoriesList = (props) => {
    const [categories, setCategories] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const category = searchParams.get('categoryname');
    const { t } = useTranslation();

    const { error, getAllCategories } = useMainService();

    useEffect(() => {
        getAllCategories()
            .then(data => setCategories(data))
            .catch(log => console.log(log));
    }, [])

    return (
        <List childClass={"navbar m-left"}>
            <NavbarItem
                to={`/`}
                src={HomeIcon}
                srcHover={HomeGreen}
                alt={"home"}
                text={t('main-link')} />
            <NavbarItem
                to={`/catalog`}
                src={MoneyIcon}
                srcHover={MoneyGreen}
                alt={"game"}
                text={t('catalog-link')} />
            <NavbarItem
                to={`/subscribes`}
                src={CheckIcon}
                srcHover={CheckGreen}
                alt={"subcribes"}
                text={t('subscribes-link')} />
            <a className="navbar__item navbar__item-divider">
                <div className="navbar__divider">
                    <span></span>
                </div>
            </a>
            <NavbarItem
                to={`/categories`}
                src={ArcadeIcon}
                srcHover={ArcadeGreen}
                alt={"categories"}
                text={t('categories-link')} />
        </List>
    );
}

export default CategoriesList;