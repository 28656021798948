import { useId, useMemo, useState } from "react";

const FieldInput = (props) => {
    const { type, text, mob } = props;
    const [word, setWord] = useState("");
    const uniqueId = useId();

    const span = useMemo(() => (mob ? <span className="mob">{mob}</span> : null), [mob]);

    return (
        <div className="fields__input">
            <input
                type={type}
                value={word}
                className={word !== "" ? "focus" : ""}
                onChange={e => setWord(e.target.value)}
                id={uniqueId} />
            <label htmlFor={uniqueId}>
                <p>
                    {text} {span}
                </p>
            </label>
        </div>
    );
}

export default FieldInput;