import React, {useEffect, useState} from 'react';
import ArrowIcon from "../../images/arrows/arrow-slide.svg";
import Menu from '../header/menu/Menu';
import MenuItem from '../header/menu/MenuItem';
import { useTranslation } from 'react-i18next';

const LngMenu = ({ }) => {
    // const [active, setActive] = useState(0);
    const languages = ['ru', 'en', 'kz'];
    const { i18n } = useTranslation();

    const [active, setActive] = useState(() => {
        // При инициализации компонента проверяем, есть ли сохраненные данные
        const savedIndex = localStorage.getItem('activeLanguageIndex');
        return savedIndex !== null ? parseInt(savedIndex, 10) : 0;
    });

    useEffect(() => {
        // При инициализации компонента устанавливаем язык из сохраненных данных
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);



    const changeLanguage = (lng, index) => {
        i18n.changeLanguage(lng);
        // Сохраняем выбранный язык и индекс активного языка
        localStorage.setItem('selectedLanguage', lng);
        localStorage.setItem('activeLanguageIndex', index);
        setActive(index);
    };

    return (
        <div className='lng'>
            <div className="lng__active">
                {languages[active]}
            </div>
            <div className="navigation__button">
                <img src={ArrowIcon} alt="arrow slide down" />
            </div>
            <div className="lng__body">
                <Menu>
                    {languages.map((item, index) => (
                        <MenuItem key={index} text={item} onClick={() => {
                            changeLanguage(languages[index], index);
                        }} />
                    ))}
                </Menu>
            </div>
        </div>
    );
};

export default LngMenu;