import React, { useEffect, useRef, useState } from 'react';
import ArrowRightSmall from "../../images/arrows/arrow-right.svg";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SliderItem = (props) => {
    const { background, name, first, margin, setSize, parentRef, sizeItem, setCurrent, count, id } = props;
    const myRef = useRef(null);
    const [changes, setChanges] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const resizeWindow = () => {
            if (margin < 0) setCurrent(0);
            else if (margin == 0) {
                setCurrent(1);
                setCurrent(0);
            }
            if (window.innerWidth <= 834) {
                setSize(-1);
            } else setSize(0);
            setChanges((changes) => !changes);
        }

        if (parentRef) {
            resizeWindow();
            window.addEventListener('resize', resizeWindow)
        }

        return () => { if (parentRef) window.removeEventListener('resize', resizeWindow) }
    }, []);

    useEffect(() => {
        if (first) {
            if (margin < (1 - count)) setCurrent(0);
            myRef.current.style.marginLeft = `${(((myRef.current.offsetWidth + (sizeItem == 0 ? 24 : 12)) * (margin)) - (count > 3 ? (myRef.current.offsetWidth + (sizeItem == 0 ? 24 : 12)) : 0))}px`;
        }
    }, [margin, changes])


    return (
        <div ref={myRef} className={"banner__item " + (first ? "banner__item-first" : "")}>
            <div className="banner__img">
                <img src={background} alt={name} draggable="false" />
            </div>
            <div className="banner__filter"></div>
            <div className="banner__content">
                <div className="banner__info">
                    {/*<div className="banner__title">{name}</div>*/}
                </div>
                <div className="banner__actions">
                    <Link to={id ? `/catalog/${id}` : "/"} className="btn btn-medium" draggable="false">
                        <p>{t('more')}</p>
                        <div className="btn__icon btn__icon-arrow">
                            <img src={ArrowRightSmall} alt="more information" />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default SliderItem;