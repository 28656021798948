import { useState } from "react";
import { useEffect } from "react";

const Blank = (props) => {
    const [current, setCurrent] = useState(0);
    const { name, type, images } = props;

    useEffect(() => {
        if (images.length > 1) {
            const timeline = setInterval(() => {
                setCurrent((current) => current < images.length - 1 ? current + 1 : 0);
            }, 5000);

            return () => {
                clearInterval(timeline);
            }
        }
    }, [images]);

    return (
        <div className="blank">
            <div className="blank__images">
                <div className="blank__image">
                    <img src={images[current]} alt="pubg mobile" />
                </div>
                <div className="blank__slider">
                    <div className="blank__slider__row">
                        {images.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => { setCurrent(index) }}
                                className={"blank__img " + (index === current ? "active" : "")}>
                                <img src={item} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="blank__content">
                <div className="blank__title">{name ? name : ''}</div>
            </div>
        </div>
    );
}

export default Blank;