import React from "react";

const Menu = (props) => {
    return (
        <div className="menu">
            {React.Children.map(props.children, child => {
                return React.cloneElement(child);
            })}
        </div>
    )
}

export default Menu;
