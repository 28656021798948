import { useEffect, useState } from "react";
import { SlideIcon } from "../../images/arrows";
import Menu from "./Menu";

const Select = (props) => {
    const [show, setShow] = useState(false)
    const [current, setCurrent] = useState(0);
    const { text, mob, items, setAnswer } = props;

    useEffect(() => {
        if (setAnswer)
            setAnswer(items.length > 0 ? items[current].value : -1);
    }, [current, items])

    const span = mob ? <span className="mob">{mob}</span> : null;

    return (
        <div onClick={() => { setShow(show => (!show)) }} className={"fields__input select "}>
            <div className="fields__input__text">{items.length > 0 ? items[current].text : ""}</div>
            <div className="fields__menu">
                {items.length > 0 ? <Menu current={current} setCurrent={setCurrent} show={show} items={items} /> : null}
            </div>
            <label className="inputted">
                <p>
                    {text} {span}
                </p>
            </label>
            <div className={"touch " + (show ? "clicked" : "")}>
                <div className="touch__icon">
                    <img src={SlideIcon} alt="arrow slide down" />
                </div>
            </div>
        </div>
    );
}

export default Select;