const Path = (props) => {
    const { values, icon, symbol } = props;

    return (
        <section className="path">
            <div className="path__row">
                {values.map((item, index) => (
                    <View key={index} index={index} length={values.length} item={item} icon={icon} symbol={symbol} />
                ))}
            </div>
        </section>
    );
}

const View = ({ index, length, item, icon, symbol }) => {
    return (<>
        <div className="path__link">{item}</div>
        {index !== length - 1 && icon ? <div className="path__icon">
            <img src={icon} alt="arrow to next" />
        </div> : null}
        {index !== length - 1 && symbol ? <div className="path__link">{symbol}</div> : null}
    </>);
}

export default Path;