import { Link } from "react-router-dom";

const MenuItem = (props) => {
    const { src, to, srcHover, onClick, alt, text, extra } = props;

    return (
        to ? <Link to={to ? to : ""} onClick={onClick} className={"menu__item"}>
            {src ? <div className={"menu__icon " + (extra ? extra : "")}>
                <img className="menu__icon-normal" src={src} alt={alt} />
                <img className="menu__icon-hover" src={srcHover} alt={alt} />
            </div> : null}
            <div className="menu__text">
                <p>{text}</p>
            </div>
        </Link> :
            <div onClick={onClick} className={"menu__item"}>
                {src ? <div className={"menu__icon " + (extra ? extra : "")}>
                    <img className="menu__icon-normal" src={src} alt={alt} />
                    <img className="menu__icon-hover" src={srcHover} alt={alt} />
                </div> : null}
                <div className="menu__text">
                    <p>{text}</p>
                </div>
            </div>
    );
}

export default MenuItem;