import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import store from "./app/store";
import {Provider} from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </Suspense>
    </Provider>

);
