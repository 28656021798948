import { useEffect, useRef, useState } from "react";
import Input from "../../components/input/Input";
import PasswordInput from "../../components/passwordInput/PasswordInput";

export const useInput = ({ type, theme, extraClass, error, onKeyDown }) => {

    const [text, setText] = useState('');
    const [correct, setCorrect] = useState(true);  // Состояние для проверки корректности ввода
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (!isFocused && inputRef.current) {
            inputRef.current.focus();
            setIsFocused(true);
        }
    }, [isFocused]);

    const view =
        type === 'text' ?
            <Input
                inputRef={inputRef}
                correct={correct}
                text={text}
                setText={setText}
                theme={theme}
                extraClass={extraClass}
                error={correct ? null : error}
                onKeyDown={onKeyDown} // Передаем onKeyDown
            /> :
            <PasswordInput
                inputRef={inputRef}
                correct={correct}
                text={text}
                setText={setText}
                theme={theme}
                extraClass={extraClass}
                error={correct ? null : error}
                onKeyDown={onKeyDown} // Передаем onKeyDown
            />;

    return [text, view, correct, setCorrect];
};
