import React, { useMemo, useState } from "react";
import Button from "../button/Button";
import Modal from "../modal/Modal";

const NavLine = (props) => {
    const [open, setOpen] = useState(false);
    const { text, time, btnText, btnSrc } = props;

    const withTime = useMemo(() => (time ?
        (<div className="nav-line__texts">
            <div className="nav-line__text">{text}</div>
            <div className="nav-line__time">{time}</div>
        </div>) :
        (<div className="nav-line__text">{text}</div>)), [time, text]);

    return (
        <div className="nav-line ">
            {withTime}
            <div className="nav-line__button">
                <Button onClick={() => {setOpen(true)}} extra={"btn-strong"} text={btnText} src={btnSrc} />
            </div>
            <Modal open={open} onClose={() => { setOpen(false) }}>
                {React.Children.map(props.children, child => {
                    return React.cloneElement(child);
                })}
            </Modal>
        </div>
    );
}

export default NavLine;