import { Link } from "react-router-dom";
import { HomeIcon, HomeIconHover, MultiIcon, MultiHoverIcon, SingleIcon, SingleHoverIcon, ActionIcon, ActionHoverIcon, ShooterIcon, ShooterHoverIcon, AdventureIcon, AdventureHoverIcon, RoleplayIcon, RoleplayHoverIcon, SimulatorIcon, SimulatorHoverIcon, StrategyIcon, StrategyHoverIcon, ArcadeIcon, ArcadeHoverIcon, SportIcon, SportHoverIcon, HorrorIcon, HorrorhoverIcon, OpenIcon, OpenHoverIcon, NarrativeIcon, NarrativeHoverIcon } from "../../images/category";
import {useTranslation} from "react-i18next";

const CategoriesItem = (props) => {
    const { name, to, icon } = props;
    const {t} = useTranslation();
    const categoriesIcons = [
        { normal: HomeIcon, hover: HomeIconHover },
        { normal: MultiIcon, hover: MultiHoverIcon },
        { normal: SingleIcon, hover: SingleHoverIcon },
        { normal: ActionIcon, hover: ActionHoverIcon },
        { normal: ShooterIcon, hover: ShooterHoverIcon },
        { normal: AdventureIcon, hover: AdventureHoverIcon },
        { normal: RoleplayIcon, hover: RoleplayHoverIcon },
        { normal: HomeIcon, hover: HomeIconHover },
        { normal: SimulatorIcon, hover: SimulatorHoverIcon },
        { normal: StrategyIcon, hover: StrategyHoverIcon },
        { normal: ArcadeIcon, hover: ArcadeHoverIcon },
        { normal: SportIcon, hover: SportHoverIcon },
        { normal: HorrorIcon, hover: HorrorhoverIcon },
        { normal: OpenIcon, hover: OpenHoverIcon },
        { normal: NarrativeIcon, hover: NarrativeHoverIcon },
    ];
    console.log(props.name)

    return (
        <Link to={to ? to : "/categories"} className="categories__item">
            <div className="categories__content">
                <div className="categories__icon">
                    <img className="categories__icon-normal" src={categoriesIcons[icon].hover}
                        alt="action category" />
                    <img className="categories__icon-hover" src={categoriesIcons[icon].hover}
                        alt="action category" />
                </div>
                <div className="categories__text">{t(name)}</div>
            </div>
        </Link>
    );
}

export default CategoriesItem;