import { Link } from "react-router-dom";
import { Like, LikeHover, LikeRed } from "../../images/system";
import Indicator from "../indicator/Indicator";
import { useEffect, useState } from "react";
import { EldenRing } from "../../images/backgrounds";
import useMainService from "../../services/MainService";
import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { PopupContext } from "../../providers/PopupProvider";

const GameForm = (props) => {
    const { name, oldPrice, price, extraClass, src, indicators, id, isMouseDown } = props;
    const [like, setLike] = useState(false);
    const [likeId, setLikeId] = useState(null);
    const { deleteFavourite, saveFavourite, getFavourites, error } = useMainService();
    const { isAuth } = useContext(AuthContext);
    const { setShow } = useContext(PopupContext);

    useEffect(() => {

        if (id) {
            getFavourites()
                .then(data => {
                    if (data && id) {
                        data.map((item) => {
                            if (item.game.id === id) {
                                setLike(true);
                                setLikeId(item.id);
                                return;
                            }
                        })
                    }
                })
        }
    }, []);

    useEffect(() => {
    }, [isMouseDown])

    useEffect(() => {
        if (isAuth) {
            if (like && !likeId) {
                saveFavourite({ "gameId": id })
                    .then();
                setLikeId(likeId);
            } else if (!like && likeId) {
                deleteFavourite(likeId).then();
                setLikeId(null);
            }
        }
    }, [like]);

    const handleLink = (e) => {
        if (isMouseDown) {
            e.preventDefault();
        }
    }

    return (
        <Link draggable={"false"} to={`/catalog/${id}`} className={"game-form " + (extraClass ? extraClass : "")}
            onClick={handleLink}>
            <div className="game-form__preview">
                <div className="game-form__img">
                    <img src={src ? src : EldenRing}
                        alt="elden ring game" draggable="false" />
                </div>

                <div className="game-form__notifications">
                    {indicators.map((item, index) =>
                        (<Indicator key={index} number={item.number} type={item.type} />))}
                </div>
                <div onClick={(e) => { e.preventDefault(); if (isAuth) setLike(like => !like); else setShow(true); }} className="game-form__fav arrow">
                    <div className="arrow-content">
                        <div className="arrow-icon">
                            <img className="arrow-icon__normal" src={!like ? Like : LikeRed}
                                alt="add favourite game" />
                            <img className="arrow-icon__hover" src={!like ? Like : LikeRed}
                                alt="add favourite game" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="game-form__content">
                <div className="game-form__title">{name}</div>
                {price ? <div className="game-form__price">
                    <div className="game-form__price-old">{oldPrice ? (oldPrice !== price ? oldPrice : '') : ''}</div>
                    <div className="game-form__price-now">{price}</div>
                    <div className="game-form__price-value">₸</div>
                </div> : null}
            </div>
        </Link>
    );
}
export default GameForm;