import { useState } from "react";
import Menu from "../menu/Menu";
import LogoLines from "../../../images/system/lines.svg";
import LogoLinesHover from "../../../images/system/lines-hover.svg";
import Logo from "../../../images/logo/text/p1-4.svg";

import MenuItem from "../menu/MenuItem";
import HomeIcon from "../../../images/category/home.svg";
import HomeIconHover from "../../../images/category/home-hover.svg";
import CartIcon from "../../../images/system/cart.svg";
import CartIconHover from "../../../images/system/cart-hover.svg";
import { Link } from "react-router-dom";
import { CatalogIcon, DiscountsNormal, SubscribesIcon, SupportIcon } from "../../../images/category";
import { useTranslation } from "react-i18next";

const LogoHeader = () => {
    const {t} = useTranslation();
    
    return (
        <div className="header__logo logo">
            <div className="logo__row">
                <Link to={"/"} className="logo__row">
                    <div className="logo__line">
                        <img className="logo__line-normal" src={LogoLines} alt="links" />
                        <img className="logo__line-hover" src={LogoLinesHover} alt="links" />
                    </div>
                    <div className="logo__row">
                        <img src={Logo} alt="logotype" />
                    </div>
                </Link>
                <div className="logo__menu">
                    <Menu>
                        <MenuItem to={"/"} src={HomeIcon} srcHover={HomeIconHover} alt="home" text={t('main-link')} />
                        <MenuItem to={"/catalog"} extra={'big'} src={CatalogIcon} srcHover={CatalogIcon} alt="games catalog" text={t('games-link')} />
                        <MenuItem to={"/discounts"} extra={'big'} src={DiscountsNormal} srcHover={DiscountsNormal} alt="discounts" text={t('discounts')} />
                        <MenuItem to={"/subscribes"} extra={'big'} src={SubscribesIcon} srcHover={SubscribesIcon} alt="subscribes" text={t('subscribes-link')} />
                        <MenuItem to={"/support"} extra={'big'} src={SupportIcon} srcHover={SupportIcon} alt="support" text={t("support")} />
                    </Menu>
                </div>
            </div>
        </div>
    )
}

export default LogoHeader;