import React, { useEffect, useRef, useState } from "react";

const List = (props) => {
    const { containerClass, childClass } = props;
    const childRef = useRef(null);
    const parentRef = useRef(null);

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        const func = () => {
            if (!childClass.includes("navbar")) {
                parentRef.current.style.height = (childRef.current.offsetHeight) + "px";
            }
        }

        window.addEventListener('resize', func);

        if (!childClass.includes("navbar")) {
            parentRef.current.style.height = (childRef.current.offsetHeight - 34) + "px";
        }

        return () => {
            window.removeEventListener('resize', func);
        }
    }, [props.children])

    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        setStartX(e.pageX - - childRef.current.offsetLeft);
        setScrollLeft(childRef.current.scrollLeft)
    }

    const handleMouseLeave = () => {
        setIsMouseDown(false);
    }

    const handleMouseUp = () => {
        setIsMouseDown(false);
    }

    const handleMouseMove = (e) => {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - childRef.current.offsetLeft;
        const walk = (x - startX) * 1;
        childRef.current.scrollLeft = scrollLeft - walk;
    }   

    return (
        <section ref={parentRef} className={"list " + (containerClass == null ? "" : containerClass)}>
            <div ref={childRef} className={`list__row ${(childClass == null ? "" : childClass)} scroll`}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
            >
                {React.Children.map(props.children, child => {
                    return React.cloneElement(child, { isMouseDown });
                })}
            </div>
        </section>
    );
}

export default List;