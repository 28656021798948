import { useState } from "react";
import { SlideIcon } from "../../images/arrows";

const Spoiler = (props) => {
    const [click, setClick] = useState(false);
    const { question, text } = props;

    return (
        <div onClick={() => { setClick(click => !click) }} className={"spoiler " + (click ? "clicked" : "")}>
            <div className="spoiler__head">
                <div className="spoiler__title main-text">{question}</div>
                <div className="spoiler__touch">
                    <div className="spoiler__touch__icon">
                        <img src={SlideIcon} alt="arrow slide down" />
                    </div>
                </div>
            </div>
            <div className="spoiler__body" dangerouslySetInnerHTML={{ __html: text }}></div>
            <div className="spoiler__line divider"></div>
        </div>
    );
}

export default Spoiler;