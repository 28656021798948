
import { useId } from "react";


const Input = ({ theme, extraClass, mob, error, correct, placeHolder, text, setText, inputRef,onKeyDown }) => {

    const inputId = useId();

    return (
        <div className={"fields__item" + (correct ? "" : " error") + " " + (extraClass ? extraClass : "")}>
            <div className={"fields__input"}>
                <input
                    ref={inputRef}
                    className={text !== "" ? "focus" : ""}
                    value={text}
                    type={"text"}
                    onInput={e => {
                        setText(e.target.value);
                    }}
                    required
                    id={inputId}

                    placeholder={placeHolder ? placeHolder : ''}
                    onKeyDown={onKeyDown}/>

                <label htmlFor={inputId}>
                    <p>
                        {theme}
                    </p>
                </label>
            </div>

            {!correct && error && (
                <div className="fields__error">
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}

export default Input;
