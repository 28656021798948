import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Конфигурация i18next
i18n
    .use(HttpApi) // загружает переводы через HTTP
    .use(LanguageDetector) // определяет язык пользователя
    .use(initReactI18next) // интеграция с react-i18next
    .init({
        fallbackLng: 'ru', // язык по умолчанию
        debug: false, // включение отладочных сообщений
        interpolation: {
            escapeValue: false, // для React это не нужно
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // путь к файлам перевода
        },
    });

export default i18n;
