import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { setLocalStorageWithExpiry } from "../../services/setLocalStorageWithExpiry";
import useMainService from "../../services/MainService";
import {getLocalStorageWithExpiry} from "../../services/getLocalStorageWithExpiry";
import { clearCart } from '../cart/cartSlice';

// Асинхронное действие для выполнения аутентификации
export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }, { rejectWithValue }) => {
        const mainService = useMainService();
        try {
            const data = await mainService.getCurrentUser(username, password);
            const expiryDate = DateTime.fromISO(data.expired, { zone: 'utc' }).setZone('local');
            setLocalStorageWithExpiry('token', data.token, expiryDate.toISO());
            return data.token;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : 'Unexpected error');
        }
    }
);

const initialState = {
    isAuth: !!getLocalStorageWithExpiry('token'),
    loginError: null, // Добавляем состояние для хранения ошибок входа
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthState: (state, action) => {
            state.isAuth = action.payload;
            if (!action.payload) {
                localStorage.removeItem('basketId');
                localStorage.removeItem('token');
            }
        },
        logout: (state) => {
            state.isAuth = false;
            localStorage.removeItem('basketId');
            localStorage.removeItem('token');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state) => {
                state.isAuth = true;
                state.loginError = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.isAuth = false;
                state.loginError = action.payload || 'Login failed';
            });
    },
});

export const { setAuthState, logout } = authSlice.actions;
export default authSlice.reducer;
