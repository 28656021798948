import { useEffect, useState } from "react";
import Search from "../components/header/search/Search";
import useMainService from "../services/MainService";
import { useTranslation } from "react-i18next";

const History = (props) => {
    const { getAllBaskets } = useMainService()
    const [baskets, setBaskets] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        getAllBaskets()
            .then(data => {
                setBaskets(data);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <main className="main">
            <div className="information">
                <div className="information__row">
                    <div className="title">{t('history-buy')}</div>
                    <div className="flow">
                        <Search extra={"full"} />

                        <div className="table third">
                            <div className="table__head">
                                <div className="table__elem">{t("cart-status")}</div>
                                <div className="table__elem">{t("date-order")}</div>
                                <div className="table__elem">{t("amount")}</div>
                            </div>
                            {baskets.map((basket, indexB) => {
                                return <div key={indexB} className="table__row">
                                    <div className="table__elem">{basket.statusId}</div>
                                    <div className="table__elem">{basket.createDate}</div>
                                    <div className="table__elem">{basket.amount}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default History;