const GameCardSkeleton = ({key}) => {
    return (
        <div key={key} className="sceleton card">
            <div className="sceleton__content">
                <div className="sceleton__item long"></div>
                <div className="sceleton__item small"></div>
            </div>
        </div>
    );
}

export default GameCardSkeleton;