import React, { createContext, useState, useContext } from "react";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [items, setItems] = useState([]); // Инициализация пустым массивом
    const [sum, setSum] = useState(0); // Инициализация нулем

    // console.log("CartProvider is changed!")
    // console.log("CartProvider, Сумма в Sum ->", sum)
    // console.log("CartProvider, Новые данные ->", items)
    return (
        <CartContext.Provider value={{ items, setItems, sum, setSum }}>
            {children}
        </CartContext.Provider>
    );
};
