import React from "react";

const FieldItem = (props) => {
    const {grid} = props;
    
    return (
        <div className={"fields__item fill " + (grid ? "grid" : "")}>
            {React.Children.map(props.children, child => {
                return React.cloneElement(child);
            })}
        </div>
    );
}

export default FieldItem;