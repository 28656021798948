const Button = (props) => {
    const { text, src, extra, onClick } = props;

    const image = src ?
        (<div className="btn__icon">
            <img src={src} alt="link to all new games" />
        </div>) : null;
    
    return (
        <div onClick={onClick} className={"btn " + (extra ? extra : "")}>
            <p>{text}</p>
            {image}
        </div>
    );
}

export default Button;