import { useContext, useEffect } from "react";
import useMainService from "./MainService"
import { BasketContext } from "../providers/BasketProvider";
import { useAuth } from "../hooks/auth/auth.hook";

export const useBasketService = () => {
    const { getActualBasket, createBasket, checkBasket, addItemToBasket, removeItemToBasket } = useMainService();
    const { isAdd, setIsAdd } = useContext(BasketContext);
    const { isAuth } = useAuth();

    useEffect(() => {
        if (isAuth) {
            if (localStorage.getItem('basketId') == null) {
                getActualBasket()
                    .then(data => {
                        console.log('i get id'); //работает
                        localStorage.setItem('basketId', data.replace(/"/g, ''))
                        checkBasket(data)
                            .then(data => {
                                setIsAdd(true);
                            })
                            .catch((err) => {
                                console.log('second cart');
                                localStorage.removeItem('basketId');
                                setIsAdd(false);
                            })
                    })
                    .catch(() => {
                        setIsAdd(false);
                    });
            } else {
                const basketId = localStorage.getItem('basketId');
                checkBasket(basketId)
                    .then(data => {
                        setIsAdd(true);
                    })
                    .catch((err) => {
                        console.log('third cart');
                        localStorage.removeItem('basketId');
                        setIsAdd(false);
                    })
            }
        }
    }, []);

    return { isAdd, setIsAdd, getActualBasket, createBasket, checkBasket, addItemToBasket, removeItemToBasket };
}