import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const FilterSort = (props) => {
    const [active, setActive] = useState(0);
    const { classes, values, setSortState } = props;
    const { t, i18n } = useTranslation();
    const [items, setItems] = useState([
        { value: t("decrease-price"), state: 1 },
        { value: t("up-price"), state: 2 }
    ]);

    useEffect(() => {
        if (setSortState)
            setSortState(active);
    }, [active]);

    useEffect(() => {
        if (values) {
            // Если значения передаются через props, перевести их
            setItems(values.map(item => ({
                ...item,
                value: t(item.value)
            })));
        }
    }, [values, t]);

    useEffect(() => {
        // Обновлять items при смене языка
        setItems([
            { value: t("decrease-price"), state: 1 },
            { value: t("up-price"), state: 2 }
        ]);
    }, [i18n.language, t]);

    return (
        <div className={"sort " + (classes ? classes : "")}>
            {items.map((item, index) => (
                <SortItem
                    key={index}
                    onClick={() => { setActive(item.state) }}
                    active={item.state === active}
                    text={t(item.value)} />))}
        </div>
    );
}

const SortItem = ({ active, text, onClick }) => {
    return (
        <div onClick={onClick} className={"sort__item" + (active ? " active" : "")}>
            <div className="sort__text">
                {text}
            </div>
        </div>
    );
}

export default FilterSort;
