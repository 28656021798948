import { useState } from "react";
import { NavLink } from "react-router-dom";

const NavBottomItem = (props) => {
    const [indicator, setIndicator] = useState(false);
    const { to, src, srcHover, alt, text } = props;

    return (
        <NavLink to={to} className={({isActive}) => ("nav-bottom__item " + (isActive ? "active" : ""))}>
            <div className="nav-bottom__content">
                <div className="nav-bottom__icon">
                    <img className="nav-bottom__icon-normal" src={src} alt={alt} />
                    <img className="nav-bottom__icon-active" src={srcHover} alt={alt} />
                </div>
                <div className={"nav-bottom__notif " + (indicator ? "" : "hidden")}>
                    <div className="indicator-dot"></div>
                </div>
            </div>
            <div className="nav-bottom__text">
                {text}
            </div>
        </NavLink>
    )
}

export default NavBottomItem;