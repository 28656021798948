const Menu = (props) => {
    const { current, setCurrent, show, items } = props;

    return (
        <div className={"menu restrict " + (show ? "" : "hide")}>
            {items.map((item, index) => (
                <div
                    onClick={() => { setCurrent(index) }}
                    key={index}
                    className={"menu__item " + (index === current ? "selected" : "")}>
                    <div className="menu__text">
                        <p>{item.text}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Menu;