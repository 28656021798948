const RoundButton = ({src, srcHover, onClick}) => {
    return (
        <div onClick={onClick} className="round-btn">
            <div className="round-btn__row back">
                <div className="round-btn__icon">
                    <img className="round-btn__icon-normal" src={src} alt="close icon" />
                    <img className="round-btn__icon-hover" src={srcHover} alt="close icon" />
                </div>
            </div>
            <div className="round-btn__indicator"></div>
        </div>
    );
}

export default RoundButton;