import { useState } from "react";
import LoupIcon from "../../../images/loupe.svg";
import CloseIcon from "../../../images/system/x.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Search = (props) => {
    const [text, setText] = useState('');
    const [focus, setFocus] = useState(false);
    const { extra } = props;
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div onKeyDown={(keyPress) => {
            if (keyPress.key === "Enter") {
                navigate(`/games?name=${text}`);
            }
        }}
            className={"search " + (focus ? "focus " : "") + (extra ? extra : "")}>
            <div className="search__row">
                <div className="search__icon">
                    <img src={LoupIcon} alt="search loup icon" />
                </div>
                <div className="search__input">
                    <input
                        onChange={e => { setText(e.target.value) }}
                        onFocus={() => { setFocus(true) }}
                        onBlur={() => { setFocus(false || text != "") }}
                        type="text"
                        value={text}
                        className="search__field"
                        placeholder={t('search-game')} />
                </div>
                <div onClick={() => { setText(""); setFocus(false) }} className="search__close">
                    <img src={CloseIcon} alt="close input" />
                </div>
            </div>
        </div>
    )
}

export default Search;