import { useEffect, useId, useMemo, useState } from "react";
import ReactInputMask from "react-input-mask";
import MaskInput from "react-maskinput";

const InputMask = ({ theme, extraClass, updateData, type, mob, password, btn, src, srcHover, placeHolder, pattern, error }) => {
    const [text, setText] = useState("");
    const [correct, setCorrect] = useState(true);
    const [hide, setHide] = useState(false);
    const inputId = useId();

    const button = btn ? (<div onClick={() => {
        password ?
            setHide((hide) => (!hide)) : setCorrect(text)
    }} className="touch">
        <div className="touch__icon">
            <img src={!hide ? src : srcHover} alt="arrow slide down" />
        </div>
    </div>) : null;

    useEffect(() => {
        if (error)
            setCorrect(false);
        else
            setCorrect(true);
    }, [error])

    const span = useMemo(() => (mob ? <span className="mob">{mob}</span> : null), [mob]);

    return (
        <div className={"fields__item" + (correct ? "" : " error") + " " + (extraClass ? extraClass : "")}>
            <div className={"fields__input"}>
                <ReactInputMask
                    mask={pattern}
                    maskChar={""}
                    type={type ? (!hide ? type : "text") : "text"}
                    alwaysShowMask
                    className={text !== "" ? "focus" : ""}
                    required
                    placeholder={placeHolder ? placeHolder : ""}
                    onBlur={() => { if (updateData) updateData(text) }}
                    onChange={e => setText(e.target.value)} />
                <label htmlFor={inputId}>
                    <p>
                        {theme} {span}
                    </p>
                </label>
                {button}
            </div>
            <div className="fields__error">
                <p>
                    {error ? error : ""}
                </p>
            </div>
        </div>
    );
}

export default InputMask;