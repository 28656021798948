import { useNavigate } from "react-router-dom";
import { ExitLink, Gear, GearHover, History, HistoryHover, Profile, ProfileHover, SupportIcon, SupportHover } from "../images/system";
import MenuItem from "../components/header/menu/MenuItem";
import { UserAvatar } from "../images/backgrounds";
import { MainPageHoverIcon, MainPageIcon } from "../images/category";
import { Guarantee } from "../images/status";
import { Facebook, Twitter } from "../images/socials/negative";
import "../scss/profile.scss";
import useMainService from "../services/MainService";
import { useEffect, useMemo, useState } from "react";
import AuthModal from "../components/authModal/AuthModal";
import { useTranslation } from "react-i18next";

const MenuProfile = (props) => {
    const navigate = useNavigate();
    const { getMe } = useMainService();
    const [current, setCurrent] = useState(null);
    const [auth, setAuth] = useState(false);
    const {t} = useTranslation();

    const authorization = useMemo(() => localStorage.getItem('token'), [current, auth]);

    useEffect(() => {
        getMe()
            .then(data => setCurrent(data));
    }, [auth])

    const profileMenus = useMemo(() => (authorization ? (<>
        <MenuItem
            text={t('profile')}
            src={Profile}
            srcHover={ProfileHover}
            to={"/profile"}
            alt={"text"} />
        <div className="menu__divider">
            <div></div>
        </div>
        <MenuItem
            text={t('history-buy')}
            src={History}
            srcHover={HistoryHover}
            to={"/history"}
            alt={"text"} />
        <div className="menu__divider">
            <div></div>
        </div>
    </>) : null), [current]);

    const exitButton = useMemo(() => (authorization ? (
        <div className="profile-form__column">
            <div onClick={() => {
                localStorage.removeItem('token');
                setAuth(auth => !auth);
            }} className="profile-form__link">
                <p>{t('logout-text')}</p>
                <div className="profile-form__icon">
                    <img src={ExitLink} alt="exit link" />
                </div>
            </div>
        </div>
    ) : null), [current]);

    const userName = useMemo(() => (current ?
        <div className="profile-form__title">{current.userName}</div> :
        <div className="profile-form__title">
            <AuthModal setAuth={() => setAuth((auth) => !auth)} />
        </div>
    ), [current]);

    return (
        <main className="content mobile">
            <div className="content__container">
                <div className="profile-form">
                    <div className="profile-form__column">
                        <div className="profile-form__ava">
                            <div className="profile-form__ava__img">
                                <img src={UserAvatar} alt="user avatar image" />
                            </div>
                            <div className="profile__info">
                                {userName}
                                {current ?
                                    <div className="profile-form__text">{current.email}</div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="profile-form__column">
                        <div className="profile-form__menu menu">
                            {profileMenus}
                            <MenuItem
                                text={t('support')}
                                src={SupportIcon}
                                srcHover={SupportHover}
                                to={"/support"}
                                alt={"text"} />
                            <div className="menu__divider">
                                <div></div>
                            </div>
                            <MenuItem
                                text={t('settings')}
                                src={Gear}
                                srcHover={GearHover}
                                to={"/profile"}
                                alt={"text"} />
                        </div>
                    </div>
                    <div className="profile-form__column">
                        <div className="profile-form__menu menu">
                            <MenuItem
                                text={t('about')}
                                src={MainPageIcon}
                                srcHover={MainPageHoverIcon}
                                to={"/about"}
                                alt={"text"} />
                            <div className="menu__divider">
                                <div></div>
                            </div>
                            <MenuItem
                                text={t('contacts')}
                                src={Profile}
                                srcHover={ProfileHover}
                                to={"/about"}
                                alt={"text"} />
                            <div className="menu__divider">
                                <div></div>
                            </div>
                            <MenuItem
                                text={t('rule-use')}
                                src={Guarantee}
                                srcHover={Guarantee}
                                to={"/user-agreement"}
                                alt={"text"} />
                            <div className="menu__divider">
                                <div></div>
                            </div>
                            <MenuItem
                                text={t('policy')}
                                src={Guarantee}
                                srcHover={Guarantee}
                                to={"/policy"}
                                alt={"text"} />
                        </div>
                    </div>
                    <div className="profile-form__column">
                        <div className="profile-form__frame">
                            <div className="profile-form__socials">
                                <div className="profile-form__social">
                                    <img src={Facebook} alt="facebook" />
                                </div>
                                <div className="profile-form__social">
                                    <img src={Twitter} alt="twitter" />
                                </div>
                            </div>
                            <p>&copy; Platform One 2023</p>
                        </div>
                    </div>
                    {exitButton}
                </div>
            </div>
        </main>
    );
}

export default MenuProfile;