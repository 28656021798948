import { useCallback, useState } from "react"

export const useHttp = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const clearError = useCallback(() => {
        setError(null);
    }, [])

    const request = useCallback(async (url, method = "GET", body = null, headers = { "Content-Type": "application/json" }, answer = true) => {
        setLoading(true);
        try {
            const res = await fetch(url, { method, body, headers });
            
            if (!res.ok) {
                console.log(`Could not fetch ${url}, status: ${res.status}`);
                let errorResponse;

                try {
                    // Попытка распарсить JSON
                    errorResponse = await res.json();
                    console.error(`Error message: ${errorResponse.message}`);
                } catch (e) {
                    // Логируем полный текст ответа
                    const text = await res.text();
                    console.error('Full error response text:', text);
                    errorResponse = { message: 'Unknown error occurred' };
                }

                throw new Error(errorResponse.message || `Request failed with status ${res.status}`);
            }

            const data = answer ? await res.json() : await res.text();

            setLoading(false);
            return data;
        } catch (e) {
            setLoading(false);
            setError(e.message || 'Something went wrong');
            throw e;
        }
    }, []);

    return { loading, error, request, clearError };
}