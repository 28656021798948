import { Link } from "react-router-dom";

const Novelty = (props) => {
    const {src, date, text, tag} = props;
    return (
        <Link to={"/news-info"} className="novelty">
            <div className="novelty__img">
                <img src={src} alt="call of duty news img" />
            </div>
            <div className="novelty__time">{date}</div>
            <div className="novelty__text">
                {text}
            </div>
            <div className="novelty__tag">
                <p>
                    {tag}
                </p>
            </div>
        </Link>
    );
}

export default Novelty;