import { useId, useState } from "react";
import { Eye, EyeClose } from "../../images/system";

const PasswordInput = ({ theme, extraClass, correct, error, mob, placeHolder, inputRef, text, setText, onKeyDown }) => {
    const [hide, setHide] = useState(false);
    const inputId = useId();

    return (
        <div className={"fields__item" + (correct ? "" : " error") + " " + (extraClass ? extraClass : "")}>
            <div className={"fields__input"}>
                <input
                    ref={inputRef}
                    required
                    className={text !== "" ? "focus" : ""}
                    value={text}
                    type={!hide ? "password" : "text"}
                    onChange={e => { setText(e.target.value); }}
                    id={inputId}
                    placeholder={placeHolder ? placeHolder : ''}
                    onKeyDown={onKeyDown}/>
                <label htmlFor={inputId}>
                    <p>
                        {theme}
                    </p>
                </label>
                <div onClick={() => { setHide(!hide) }} className="touch">
                    <div className="touch__icon">
                        <img src={!hide ? Eye : EyeClose} alt="eye icon" />
                    </div>
                </div>
            </div>
            {!correct && error && (
                <div className="fields__error">
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}

export default PasswordInput;
