import "../scss/profile.scss";
import Avatar from "../images/backgrounds/user-avatar.png";
import Input from "../components/input/Input";
import FieldItem from "../components/fieldItem/FieldItem";
import FieldInput from "../components/fieldInput/FieldInput";
import Select from "../components/select/Select";
import NavLine from "../components/navLine/NavLine";
import { EditPencil } from "../images/system";
import Button from "../components/button/Button";
import { useEffect, useState } from "react";
import useMainService from "../services/MainService";
import { Bank } from "../images/payment";
import InputMask from "../components/inputMask/InputMask";
import { useNavigate } from "react-router-dom";

const PaymentTalk = () => {
    const { getAnswerTalkBank } = useMainService();
    const [numberCard, setNumberCard] = useState('');
    const [cardErr, setCardErr] = useState(null);
    const [dateExp, setDateExp] = useState('');
    const [dateErr, setDateErr] = useState(null);
    const [cvv, setCvv] = useState('');
    const [cvvErr, setCvvErr] = useState(null);
    const [fullName, setFullName] = useState('');
    const [fullNameErr, setFullNameErr] = useState(null);

    return (
        <main className="content">
            <div className="content__container">
                <div className="profile-form border">
                    <div className="profile-form__column">
                        <div className="profile-form__ava">
                            <div className="profile-form__ava__logo">
                                <img src={Bank} alt={"Visa mastercard"} />
                            </div>
                        </div>
                    </div>
                    <div className="topic">Форма TalkBank</div>
                    <div className="profile-form__column">
                        <div className="profile-form__fields fields">
                            <FieldItem>
                                <InputMask
                                    theme={"Номер карты"}
                                    pattern={"9999 9999 9999 9999"}
                                    placeHolder={"XXXX-XXXX-XXXX-XXXX"}
                                    updateData={(text) => {
                                        setNumberCard(text);
                                        if (text !== "") setCardErr(null);
                                    }}
                                />
                            </FieldItem>
                            <InputMask
                                theme={"Срок карты"}
                                pattern={"99/99"}
                                placeHolder={"01/23"}
                                updateData={(text) => {
                                    setDateExp(text);
                                    if (text !== "") setDateErr(null);
                                }}
                            />
                            <InputMask
                                theme={"CVV"}
                                pattern={"999"}
                                placeHolder={"123"}
                                updateData={(text) => {
                                    setCvv(text);
                                    if (cvv !== "") setCvvErr(null);
                                }}
                            />
                            <FieldItem>
                                <Input
                                    theme={"ФИО владельца"}
                                    check={text => text !== ""}
                                    updateData={(text) => {
                                        setFullName(text);
                                        if (fullName !== "") setFullNameErr(null);
                                    }}
                                />
                            </FieldItem>
                            {/* <FieldItem grid={true}>
                                <FieldInput text={"CVV"} type={"number"} />
                                <Select text={"Месяц"} mob={"рождения"} items={months} />
                                <FieldInput text={"Год"} mob={"рождения"} type={"number"} />
                            </FieldItem>
                            <FieldItem>
                                <Select text={"Страна"} items={countries} />
                            </FieldItem> */}
                            {/* <FieldItem>
                                <NavLine text={"login@gmail.com"} btnText={"Сменить"} btnSrc={EditPencil}>
                                    <div className="modal__space">
                                        <div className="modal__title">Смена электронной почты</div>
                                        <Input
                                            extraClass={"line"}
                                            theme={"Новая электронная почта"}
                                            check={text => true}
                                        />
                                        <Button text={"Отправить код подтверждения"} extra={"btn-light line"} />
                                    </div>
                                </NavLine>
                            </FieldItem>

                            <FieldItem>
                                <NavLine text={"+7 777 777 77 77"} btnText={"Сменить"} btnSrc={EditPencil} >
                                    <div className="modal__space">
                                        <div className="modal__title">Смена номера телефона</div>
                                        <Input
                                            extraClass={"line"}
                                            theme={"Введите номер телефона"}
                                            check={text => true}
                                        />
                                        <Button text={"Отправить код подтверждения"} extra={"btn-light line"} />
                                    </div>
                                </NavLine>
                            </FieldItem>

                            <FieldItem>
                                <NavLine text={"Пароль"} time={"Последнее обновление 1 янв. 2023"} btnText={"Сменить"} btnSrc={EditPencil} >
                                    <div className="modal__space">
                                        <div className="modal__title">Смена пароля</div>
                                        <div className="fields">
                                            <FieldItem>
                                                <FieldInput text={"Введите текущий пароль"} type={"text"} />
                                            </FieldItem>
                                            <Input
                                                theme={"Придумайте новый пароль"}
                                                error={"Недопустимое имя"}
                                                check={text => text !== ""}
                                            />
                                            <Input
                                                theme={"Подтвердите новый пароль"}
                                                error={"Недопустимое имя"}
                                                check={text => text !== ""}
                                            />
                                        </div>
                                        <Button text={"Сохранить пароль"} extra={"btn-light line"} />
                                    </div>
                                </NavLine>
                            </FieldItem> */}
                        </div>
                    </div>
                    <div className="profile-form__column">
                        <Button text={"Оплатить"} extra={"btn-light"}
                            onClick={() => {
                                if (numberCard === "") setCardErr("Номер карты не может быть пустым");
                                if (dateExp === "") setDateErr("Срок карты не может быть пустым");
                                if (cvv === "") setCvvErr("CVV код не может быть пустым");
                                if (fullName === "") setFullNameErr("Фио не может быть пустым");
                            }} />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default PaymentTalk;