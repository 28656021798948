import { useTranslation } from "react-i18next";
import Novelty from "../components/novelty/Novelty";
import { Callda, IndiGame, Lego, Stream } from "../images/backgrounds";

const News = (props) => {
    const news = [
        {
            src: Lego,
            date: "1 час назад",
            text: "Sonic may have just taken the crown for the coolest-ever Lego set",
            tag: "PlatformOne",
        },
        {
            src: Callda,
            date: "Вчера",
            text: "Sonic may have just taken the crown for the coolest-ever Lego set",
            tag: "Скидки",
        },
        {
            src: IndiGame,
            date: "18 апр.",
            text: "Sonic may have just taken the crown for the coolest-ever Lego set",
            tag: "Новости",
        },
        {
            src: Stream,
            date: "31 дек. 2022",
            text: "Sonic may have just taken the crown for the coolest-ever Lego set",
            tag: "Из мира игр",
        },
    ]

    const { t } = useTranslation();

    return (
        <main className="main">
            <div className="main__container">
                <div className="title mpd">{t('news')}</div>
                <div className="news">
                    <div className="news__row">
                        {news.map((item, index) => (
                            <div className="news__item" key={index}>
                                <Novelty
                                    src={item.src}
                                    date={item.date}
                                    text={item.text}
                                    tag={item.tag} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default News;