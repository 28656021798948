import { useTranslation } from "react-i18next";
import GameCard from "../components/gameCard/GameCard";
import Games from "../components/games/Games";
import List from "../components/list/List";
import NewsDetails from "../components/newsDetails/NewsDetails";
import Novelty from "../components/novelty/Novelty";
import Path from "../components/path/Path"
import Publish from "../components/publish/Publish";
import { RightLow } from "../images/arrows"
import { Lego, Stream } from "../images/backgrounds";

const NewsInfo = (props) => {
    const descriptions = [
        `Sonic, please forgive me for all the times I've poo-pooed your games. Because holy hell, you
        sure do make for a cool-ass Lego set. \n
        Yep, the blue blur has teamed up with Lego to create four new sets that are due to release
        this August. It's not the first time the two have collaborated—the toy brick brand released
        a Green Hill Zone set(opens in new tab) back in 2021. We're actually getting another Green
        Hill Zone set with this new batch of releases, but if you ask me this new rendition is
        significantly cooler. The "Loop Challenge" set comes with—as the name suggests—a loop, as
        well as other pieces to create a course. A hamster-wheeled Sonic can be strapped to a spring
        catapult and launched across the kit and straight into a meched-up Dr. Eggman. \n
        It's easily my favourite—and I imagine it'll be the favourite of many others—but cool
        contraptions come at a price. It's the most expensive of the four coming in at $99.99 /
        £94.99. It does come with a fair few character figures, though: Sonic can be freed from his
        hamster ball chamber, Dr. Eggman can be pulled from his mech, and Amy is included, too. It
        comes with a few creatures too, like a flicky, pocky and badniks chopper.`,
        `The other three sets are a little easier on the old bank account. "Amy's Animal Rescue
        Island" is an adorable set with a sandcastle, waterfall and a Tails figure to accompany Amy,
        with the option to set up a small trap for the included badniks chopper. "Tails' Workshop
        and Tornado Plane" includes Sonic's sidekick and his iconic aircraft, while "Sonic's Speed
        Sphere Challenge" is a condensed version of the more expensive loop course. It has Sonic in
        his spherical prison again, but with a smaller selection of pieces to catapult him under and
        over. \n
        They're all pretty low on the brick count thanks to larger set pieces and more interactive
        play, something to bear in mind if you're someone like me who craves sets with tons of tiny
        intricate pieces. Here's each set, their price and the number of pieces included:`
    ]

    const list = [
        "Sonic's Green Hill Zone Loop Challenge<del>(opens in new tab) || $99.99 / £94.99 || 802 pieces",
        "Amy's Animal Rescue Island<del>(opens in new tab) || $49.99 / £46.99 || 388 pieces",
        "Tails' Workshop and Tornado Plane<del>(opens in new tab) || $39.99 / £37.99 || 376 pieces",
        "Sonic's Speed Sphere Challenge<del> (opens in new tab)|| $34.99 / £24.99 || 292 pieces",
    ]

    const {t} = useTranslation();

    return (
        <main className="content">
            <div className="content__container start">
                <Path values={["Главная", "Новости", "Categorytag"]} icon={RightLow} />

                <div className="news-title">Sonic may have just taken the crown for the coolest-ever Lego set</div>

                <NewsDetails src={Lego} descriptions={descriptions} list={list} />

                <Games title={"Успейте купить Sonic"}>
                    <List containerClass={"games__list"} childClass={"games__list__row"}>
                        <GameCard name={"Elden ring"} oldPrice={"3 990"} price={"3 990"} />
                        <GameCard name={"Elden ring"} oldPrice={"3 990"} price={"3 990"} />
                    </List>
                </Games>

                <Publish author={t('author')} date={"24 апр."} tags={["Firsttag", "Another tag", "Tag", "Verylongtag"]} />

                <Games title={t('other-news')}>
                    <List containerClass={"games__list"} childClass={"games__list__row"}>
                        <Novelty
                            src={Lego}
                            date={"31 дек. 2022"}
                            text={"Sonic may have just taken the crown for the coolest-ever Lego set"}
                            tag={"Из мира игр"} />

                        <Novelty
                            src={Stream}
                            date={"31 дек. 2022"}
                            text={"Sonic may have just taken the crown for the coolest-ever Lego set"}
                            tag={"Из мира игр"} />

                        <Novelty
                            src={Stream}
                            date={"31 дек. 2022"}
                            text={"Sonic may have just taken the crown for the coolest-ever Lego set"}
                            tag={"Из мира игр"} />
                    </List>
                </Games>
            </div>
        </main>
    );
}

export default NewsInfo;