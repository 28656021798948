import { Link } from "react-router-dom";
import NavBottomItem from "./NavBottomItem";
import { HomeIcon, HomeIconHover } from "../../images/category";
import { Like as LikeIcon, LikeHover as LikeIconHover, Cart as CartIcon, CartHover as CartIconHover, Notification as NotifIcon, NotificationHover as NotifIconHover, Lines as LinesIcon, LinesHover as LinesIconHover } from "../../images/system";


const NavBottom = (props) => {
    return (
        <section className="nav-bottom">
            <div className="nav-bottom__row">
                <NavBottomItem to={"/"} src={HomeIcon} srcHover={HomeIconHover} alt={"home"} text={"Главная"} />
                <NavBottomItem to={"/favourites"} src={LikeIcon} srcHover={LikeIconHover} alt={"favourites link"} text={"Избранные"} />
                <NavBottomItem to={"/cart"} src={CartIcon} srcHover={CartIconHover} alt={"cart link"} text={"Корзина"} />
                <NavBottomItem to={"/categories"} src={NotifIcon} srcHover={NotifIconHover} alt={"notification"} text={"Уведомление"} />
                <NavBottomItem to={"/menu"} src={LinesIcon} srcHover={LinesIconHover} alt={"menu link"} text={"Меню"} />
            </div>
        </section>
    );
}

export default NavBottom;