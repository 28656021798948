import "../scss/profile.scss";
import Avatar from "../images/backgrounds/user-avatar.png";
import Input from "../components/input/Input";
import FieldItem from "../components/fieldItem/FieldItem";
import FieldInput from "../components/fieldInput/FieldInput";
import Select from "../components/select/Select";
import NavLine from "../components/navLine/NavLine";
import { EditPencil } from "../images/system";
import Button from "../components/button/Button";
import useMainService from "../services/MainService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
    const { getAllCountries, getMe } = useMainService();
    const {t} = useTranslation();

    const months = [
        { text: t("january"), value: 1 },
        { text: t("february"), value: 2 },
        { text: t("march"), value: 3 },
        { text: t("april"), value: 4 },
        { text: t("may"), value: 5 },
        { text: t("june"), value: 6 },
        { text: t("july"), value: 7 },
        { text: t("august"), value: 8 },
        { text: t("september"), value: 9 },
        { text: t("october"), value: 10 },
        { text: t("november"), value: 11 },
        { text: t("december"), value: 12 },
    ];
    const [countries, setCountries] = useState([]);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');


    useEffect(() => {
        getAllCountries()
            .then(data => setCountries(data.map((item, index) => ({ text: item.name, value: item.id }))));
    }, [])

    useEffect(() => {
        const fetchUserProfile = async () => {
            const userProfile = await getMe();
            if (userProfile) {
                setEmail(userProfile.email);
                setPhoneNumber(userProfile.phoneNumber);
            }
        };

        fetchUserProfile();
    }, []);


    return (
        <main className="content">
            <div className="content__container">
                <div className="topic">{t('edit-profile')}</div>
                <div className="profile-form">
                    <div className="profile-form__column">
                        <div className="profile-form__ava">
                            <div className="profile-form__ava__img">
                                <img src={Avatar} alt="user avatar image" />
                            </div>
                            <div className="profile-form__ava__btn">
                                <input id="userAvatar" type="file" accept=".jpg,.jpeg,.png,.gif,.webp,.svg" />
                                <label htmlFor="userAvatar" className="btn btn-strong">
                                    <p>{t("change-img")}</p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="profile-form__column">
                        <div className="profile-form__fields fields">
                            <Input
                                theme={t("name")}
                                error={t("name-err")}
                                check={text => text !== ""}
                                setText={setName}
                            />
                            <Input
                                theme={t("surname")}
                                error={t("surname-err")}
                                check={text => text !== ""}
                                setText={setSurname}
                            />
                            <FieldItem grid={true}>
                                <FieldInput text={t("day")} mob={"рождения"} type={"number"} />
                                <Select text={"month"} mob={"рождения"} items={months} />
                                <FieldInput text={t("year")} mob={"рождения"} type={"number"} />
                            </FieldItem>
                            <FieldItem>
                                <Select text={t("country")} items={countries} />
                            </FieldItem>
                            <FieldItem>
                                <NavLine text={email} btnText={"Сменить"} btnSrc={EditPencil}>
                                    <div className="modal__space">
                                        <div className="modal__title">{t("change-email")}</div>
                                        <Input
                                            extraClass={"line"}
                                            theme={t("new-email")}
                                            check={text => true}
                                        />
                                        <Button text={t("code-access")} extra={"btn-light line"} />
                                    </div>
                                </NavLine>
                            </FieldItem>

                            <FieldItem>
                                <NavLine text={phoneNumber || "no-number"} btnText={"Сменить"} btnSrc={EditPencil} >
                                    <div className="modal__space">
                                        <div className="modal__title">{t("change-phone")}</div>
                                        <Input
                                            extraClass={"line"}
                                            theme={t("new-phone")}
                                            check={text => true}
                                        />
                                        <Button text={t("code-access")} extra={"btn-light line"} />
                                    </div>
                                </NavLine>
                            </FieldItem>

                            <FieldItem>
                                <NavLine text={"Пароль"} time={"Последнее обновление 1 янв. 2023"} btnText={"Сменить"} btnSrc={EditPencil} >
                                    <div className="modal__space">
                                        <div className="modal__title">{t("change-pass")}</div>
                                        <div className="fields">
                                            <FieldItem>
                                                <FieldInput text={t("current-pass")} type={"text"} />
                                            </FieldItem>
                                            <Input
                                                theme={t("new-pass")}
                                                error={t("pass-err")}
                                                check={text => text !== ""}
                                            />
                                            <Input
                                                theme={t("access-pass")}
                                                error={t("pass-err")}
                                                check={text => text !== ""}
                                            />
                                        </div>
                                        <Button text={t("save-pass")} extra={"btn-light line"} />
                                    </div>
                                </NavLine>
                            </FieldItem>
                        </div>
                    </div>
                    <div className="profile-form__column">
                        <Button text={t("save-change")} extra={"btn-light"} />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default EditProfile;