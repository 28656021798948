import { SingleHoverIcon, SingleIcon } from "../../images/category";

const Publish = (props) => {
    const {author, date, tags} = props;
    return (
        <section className="publish">
            <div className="publish__item">
                <div className="clear">
                    <div className="clear__text">{author}</div>
                    <div className="clear__icon">
                        <img className="clear__icon-normal" src={SingleIcon} />
                        <img className="clear__icon-hover"
                            src={SingleHoverIcon} alt="single button" />
                    </div>
                </div>
            </div>
            <div className="publish__text">•</div>
            <div className="publish__text">{date}</div>
            <div className="publish__text">•</div>
            <div className="publish__item">
                <ul className="publish__list">
                    {tags.map((item, index) => (<li key={index}>{item}</li>))}
                </ul>
            </div>
        </section>
    );
}

export default Publish;