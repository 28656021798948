const NewsDetails = (props) => {
    const {src, descriptions, list} = props;
    
    return (
        <section className="news-details">
            <div className="news-details__row">
                <div className="news-details__img">
                    <img src={src} alt="lego news big img" />
                </div>

                {descriptions.map((item, index) => (
                    <div key={index} className="news-details__text">
                        {item}
                    </div>
                ))}

                <ul className="news-details__list">
                    {list.map((item, index) => (
                        <li key={index}><span>{item.split('<del>')[0]}</span>{item.split('<del>')[1]}</li>
                    ))}
                </ul>
            </div>
        </section>
    );
}

export default NewsDetails;