import React, { useEffect, useMemo, useState } from "react";

export const BasketContext = React.createContext({ isAdd: false });


export const BasketProvider = ({ children }) => {
    const [isAdd, setIsAdd] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('basketId')) setIsAdd(true);
        else setIsAdd(false);
    }, [])

    useEffect(() => {
        const basketId = localStorage.getItem('basketId');
        setIsAdd(!!basketId);  // Устанавливаем isAdd на основе наличия basketId
    }, []);

    const value = useMemo(() => ({ isAdd, setIsAdd }), [isAdd]);

    return (
        <BasketContext.Provider value={value} >
            {children}
        </BasketContext.Provider>
    )
}
