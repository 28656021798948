import { useTranslation } from "react-i18next";


const Policy = () => {
    const {t} = useTranslation();

    return (
        <main className="main">
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t('public-offer')}</div>
                    <div className="news-title-2">{t('public-offer-header')}</div>
                    <div class="news-title-2">{t('public1')}</div>
                    <div className="main-text">{t('public11')}</div>
                    <div className="main-text">{t('public12')}</div>
                    <div className="main-text">{t('public13')}</div>
                    <div className="main-text">{t('public131')}</div>
                    <div className="main-text">{t('public132')}</div>
                    <div className="main-text">{t('public133')}</div>
                    <div className="main-text">{t('public14')}</div>
                    <div className="main-text">{t('public15')}</div>
                    <div className="main-text">{t('public16')}</div>
                    <div className="news-title-2">{t('public2')}</div>
                    <div className="main-text">{t('public21')}</div>
                    <div className="main-text">{t('public22')}</div>
                    <div className="news-title-2">{t('public3')}</div>
                    <div className="main-text">{t('public31')}</div>
                    <div className="main-text">{t('public32')}</div>
                    <div className="news-title-2">{t('public4')}</div>
                    <div className="main-text">{t('public41')}</div>
                    <div className="main-text">{t('public42')}</div>
                    <div className="main-text">{t('public43')}</div>
                    <div className="news-title-2">{t('public5')}</div>
                    <div className="main-text">{t('public51')}</div>
                    <div className="main-text">{t('public52')}</div>
                    <div className="main-text">{t('public53')}</div>
                    <div className="news-title-2">{t('public6')}</div>
                    <div className="main-text">{t('public61')}</div>
                    <div className="news-title-2">{t('public7')}</div>
                    <div className="main-text">{t('public71')}</div>
                    <div className="main-text">{t('public72')}</div>
                    <div className="main-text">{t('public73')}</div>
                    <div className="main-text">{t('public74')}</div>
                    <div className="news-title-2">{t('public8')}</div>
                    <div className="main-text">{t('public81')}</div>
                    <div className="main-text">{t('public82')}</div>
                    <div className="main-text">{t('public83')}</div>
                    <div className="main-text">{t('public84')}</div>
                    <div className="main-text">{t('public85')}</div>
                    <div className="news-title-2">{t('public9')}</div>
                    <div className="main-text">{t('public91')}</div>
                    <div className="main-text">{t('public92')}</div>
                    <div className="news-title-2">{t('public10')}</div>
                    <div className="main-text">{t('public101')}</div>
                    <div className="main-text">{t('public102')}</div>
                    <div className="news-title-2">{t('public11-0')}</div>
                    <div className="main-text">{t('public111')}</div>
                    <div className="main-text">{t('public112')}</div>
                    <div className="main-text">{t('public113')}</div>
                    <div className="news-title-2">{t('public12-0')}</div>
                    <div className="main-text">{t('public121')}</div>
                    <div className="main-text">{t('public122')}</div>
                    <div className="news-title-2">{t('public13-0')}</div>
                    <div className="main-text">{t('public13-1')}</div>
                    <div className="main-text">{t('public13-2')}</div>
                    <div className="main-text">{t('public13-3')}</div>
                    <div className="main-text">{t('public13-4')}</div>
                    <div className="main-text">{t('public13-5')}</div>
                    <div className="main-text">{t('public13-6')}</div>
                    <div className="main-text">{t('public13-7')}</div>
                </div>
            </div>
        </main>
    );
}

export default Policy;