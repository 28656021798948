import { useTranslation } from "react-i18next";

const UserAgreement = (props) => {
    const { t } = useTranslation();

    return (
        <main className="main">
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t('user-agreement-header')}</div>
                    <div className="news-title-2">{t('user-agreement1')}</div>
                    <div class="main-text">{t('user-agreement11')}</div>
                    <div class="main-text">{t('user-agreement12')}</div>
                    <div className="news-title-2">{t('user-agreement2')}</div>
                    <div className="main-text">{t('user-agreement21')}</div>
                    <div className="main-text">{t('user-agreement22')}</div>
                    <div className="news-title-2">{t('user-agreement3')}</div>
                    <div className="main-text">{t('user-agreement31')}</div>
                    <div className="main-text">{t('user-agreement32')}</div>
                    <div className="main-text">{t('user-agreement33')}</div>
                    <div className="news-title-2">{t('user-agreement4')}</div>
                    <div className="main-text">{t('user-agreement41')}</div>
                    <div className="main-text">{t('user-agreement42')}</div>
                    <div className="news-title-2">{t('user-agreement5')}</div>
                    <div className="main-text">{t('user-agreement51')}</div>
                    <div className="main-text">{t('user-agreement52')}</div>
                    <div className="news-title-2">{t('user-agreement6')}</div>
                    <div className="main-text">{t('user-agreement61')}</div>
                    <div className="main-text">{t('user-agreement62')}</div>
                </div>
            </div>
        </main>
    );
}

export default UserAgreement;