// providers/AuthProvider.js
import React, { useEffect, useMemo, useState } from "react";
import { getLocalStorageWithExpiry } from "../services/getLocalStorageWithExpiry";

export const AuthContext = React.createContext({ isAuth: false });

const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        if (getLocalStorageWithExpiry('token')) setIsAuth(true);
        else {
            setIsAuth(false);
            localStorage.removeItem('basketId');
        }
    }, []);

    const value = useMemo(() => ({ isAuth, setIsAuth }), [isAuth]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
