import {Link} from "react-router-dom";
import Logo from "../../images/logo/text/p1-5.svg";
import { Google, Telegram, YouTube, Instagram, TikTok } from "../../images/socials/negative";
import { useTranslation } from "react-i18next";


const Footer = () => {
    const { t } = useTranslation();

    const goToPolicy = () => {
        window.location.replace("/policy");
    };

    return (
        <footer className="footer">
            <div className="footer__row">
                <div className="footer__content space">
                    <div className="footer__logo logo">
                        <div className="logo__row">
                            <img src={Logo} alt="logotype" />
                        </div>
                    </div>
                    <div className="footer__content" style={{marginLeft:"10%"}}>
                        <Link to={"/about"} className="footer__link">{t('about')}</Link>
                        <Link to={'/support#contact-point'} className="footer__link">{t('contacts')}</Link>
                        <Link to={'/payment-instruction'} className="footer__link">{t('payment-instruction')}</Link>
                    </div>

                    <div className="footer__content">
                        {/*Ссылка на VK: https://vk.com/platformoneonline*/}
                        <a href='https://google.com' target="_blank" className="footer__icon">
                            <img src={Google} alt="Google icon" />
                        </a>
                        <a href='https://telegram.org/' target="_blank" className="footer__icon">
                            <img src={Telegram} alt="Telegram icon" />
                        </a>
                        <a href='https://www.youtube.com/' target="_blank" className="footer__icon">
                            <img src={YouTube} alt="YouTube icon" />
                        </a>
                        <a href='https://www.instagram.com/' target="_blank" className="footer__icon">
                            <img src={Instagram} alt="Instagram icon" />
                        </a>
                        <a href='https://www.tiktok.com/' target="_blank" className="footer__icon">
                            <img src={TikTok} alt="TikTok icon" />
                        </a>
                    </div>
                </div>
                <div className="footer__content">
                    <div className="footer__copy">&copy; {t('copy-text')}</div>
                </div>
                <div className="footer__content">
                    <Link to={"/user-agreement"} className="footer__link border">{t('rule-use')}</Link>
                    <Link to={"/policy"} className="footer__link border">{t('policy-header')}</Link>
                    <Link to={"/public-offer"} className="footer__link border">{t('public-offer')}</Link>
                </div>
                <div className="footer__content">
                    <Link to={'/support'} className="footer__link border">{t('support')}</Link>
                    <Link to={'/catalod'} className="footer__link border">{t('catalog-link')}</Link>
                    <Link to={'/subscribes'} className="footer__link border">{t('subscribes-link')}</Link>
                    <Link to={'/discounts'} className="footer__link border">{t('discounts')}</Link>
                    <Link to={'/categories'} className="footer__link border">{t('categories')}</Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;