import React, { useEffect, useMemo, useState } from "react";
import ArrowRight from "../../images/arrows/arrow-right.svg";
import Button from "../button/Button";
import useMainService from "../../services/MainService";
import List from "../list/List";
import CategoryItemSkeleton from "../skeletons/CategoryItemSkeleton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Genres = (props) => {
    const { title, noButton, render, listClass } = props;
    const { error, getAllCategories } = useMainService();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const skeletons = [1, 1, 1, 1, 1, 1];
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true);
        getAllCategories()
            .then(val => { onListLoaded(val); })
            .catch(log => console.log(log));
    }, []);

    const onListLoaded = (chars) => {
        setLoading(false);
        setCategories(chars);
    }

    const children = useMemo(() => (loading ?
        skeletons.map((item, index) => (<CategoryItemSkeleton key={index} />))
        :
        categories.map((item, index) => {
            return render(item.name, item.id)
        })), [loading, categories]);

    const element = <List containerClass={"games__list"} childClass={listClass ? listClass : "categories"}>
        {children}
    </List>;

    return (
        <section className="games" id="new-games">
            <div className="games__row">
                <div className="games__nav nav-line ">
                    <div className="nav-line__text">{title}</div>
                    <div className="nav-line__button">
                        {noButton ? null : <Button onClick={()=>{navigate("/categories")}} extra={"btn-strong"} text={t('all')} src={ArrowRight} />}
                    </div>
                </div>

                {element}
            </div>
        </section>
    );
}

export default Genres;