import {Routes, Route, HashRouter} from "react-router-dom";

import Header from "../header/Header";
import Footer from "../footer/Footer";

import "../../scss/style.scss";
import { About, Home, Cart, Catalog, Categories, EditProfile, Favourites, GameInfo, History, NewsInfo, News, Payment, Policy, ProfilePage, Support, MenuProfile, Subscribes, PaymentTalk, Discounts, CatalogGames, UserAgreement } from "../../pages";
import { BasketProvider } from "../../providers/BasketProvider";
import Disclaimer from "../disclaimer/Disclaimer";
import { PopupProvider } from "../../providers/PopupProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthProvider from "../../providers/AuthProvider";
import {CartProvider} from "../../providers/CartProvider";
import PublicOffer from "../../pages/PublicOffer";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import PaymentInstruction from "../../pages/PaymentInstruction";
import {useDispatch} from "react-redux";
import useMainService from "../../services/MainService";
import {useEffect} from "react";
import {setActiveCurrency, setCurrencies} from "../../features/cart/cartSlice";


const App = () => {
    const dispatch = useDispatch();
    const { getCurrency } = useMainService();

    // useEffect(() => {
    //     const savedCurrencies = JSON.parse(localStorage.getItem('currencies'));
    //
    //     // Устанавливаем сохраненные валюты, если они есть
    //     if (savedCurrencies) {
    //         dispatch(setCurrencies(savedCurrencies));
    //         dispatch(setActiveCurrency(savedCurrencies[0]));
    //     }
    //
    //     // Функция для получения курсов валют с сервера
    //     const fetchCurrencies = async () => {
    //         try {
    //             const data = await getCurrency();
    //             if (data && Array.isArray(data)) {
    //                 dispatch(setCurrencies(data)); // Обновляем курсы валют в Redux
    //                 localStorage.setItem('currencies', JSON.stringify(data)); // Сохраняем курсы в localStorage
    //
    //                 // Устанавливаем активную валюту, если она еще не выбрана
    //                 if (!savedCurrencies) {
    //                     dispatch(setActiveCurrency(data[0]));
    //                 }
    //             }
    //         } catch (err) {
    //             console.error('Ошибка при получении данных валют:', err);
    //         }
    //     };
    //
    //     // Выполняем запрос при загрузке компонента
    //     fetchCurrencies();
    //
    //     // Устанавливаем интервал для запроса каждые 2 часа
    //     const intervalId = setInterval(fetchCurrencies, 2 * 60 * 60 * 1000); // 2 часа
    //
    //     // Очищаем интервал при размонтировании компонента
    //     return () => clearInterval(intervalId);
    //
    //     // Пустой массив зависимостей означает, что эффект выполнится только один раз при первом рендере
    // }, []);

    return (
        <HashRouter>
            <ScrollToTop />
            <AuthProvider>
                <PopupProvider>
                    <BasketProvider>
                        <CartProvider>
                        {/*<Disclaimer />*/}
                        <Header />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/cart" element={<Cart />} />
                            <Route path="/catalog" element={<Catalog />} />
                            <Route path="/games" element={<CatalogGames />} />
                            <Route path="/subscribes" element={<Subscribes />} />
                            <Route path="/discounts" element={<Discounts />} />
                            <Route path="/catalog/:id" element={<GameInfo />} />
                            <Route path="/categories" element={<Categories />} />
                            <Route path="/edit-profile" element={<EditProfile />} />
                            <Route path="/favourites" element={<Favourites />} />
                            <Route path="/history" element={<History />} />
                            <Route path="/news-info" element={<NewsInfo />} />
                            <Route path="/news" element={<News />} />
                            <Route path="/payment" element={<Payment />} />
                            <Route path="/payment-talk" element={<PaymentTalk />} />
                            <Route path="/policy" element={<Policy />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/support" element={<Support />} />
                            <Route path="/user-agreement" element={<UserAgreement />} />
                            <Route path="/public-offer" element={<PublicOffer />} />
                            <Route path="/payment-instruction" element={<PaymentInstruction />} />
                            <Route path="/menu" element={<MenuProfile />} />
                        </Routes>
                        <Footer />
                        </CartProvider>
                    </BasketProvider>
                </PopupProvider>
            </AuthProvider>
        </HashRouter>
    )
}

export default App;